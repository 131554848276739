import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import publishYourTournamentIcon from "images/Publish-Your-Tournament.svg";
import preRegistrationIcon from "images/Pre-Registration.svg";
import onlinePaymentsIcon from "images/Online-Payments.svg";
import automaticSeedingIcon from "images/Automatic-Seeding.svg";
import userFriendlyInterfacesIcon from "images/User-Friendly-Interface.svg";
import uptimeGauranteedIcon from "images/Uptime-Guaranteed.svg";
import followTeamAndAthletesIcon from "images/Follow-Team-and-Athletes.svg";
import estimatedMatchTimeIcon from "images/Estimated-Match-Time.svg";
import tournamentAnouncementsIcon from "images/Tournament-Announcements.svg";
import liveResultsIcon from "images/Live-Results.svg";
import wrestlerProfilesIcon from "images/Wrestler-Profiles.svg";
import detailedStatisticsIcon from "images/Detailed-Statistics.svg";
import headToHeadComparisonsIcon from "images/Head-to-Head-Comparisons.svg";
import weightManagementIcon from "images/Weight-Management.svg";
import rosterManagementIcon from "images/Roster-Management.svg";
import findAndRegisterForMeetsIcon from "images/Find-and-Register-for-Meets.svg";
// import accessibleSchedulesIcon from "images/Accessible-Schedules.svg";
import automaticRecordsUpdatesIcon from "images/Automatic-Records-Updates.svg";
import builtInWeightCriteriaIcon from "images/Built-In-Weight-Criteria.svg";
import userFriendlyScoringInterfaceIcon from "images/User-Friendly Scoring Interface.svg";
import mobileAndDesktopCompatibilityIcon from "images/Mobile-and-Desktop-Compatibility.svg";
import realTimeResultsIcon from "images/Real-Time-Results.svg";

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0 items-center justify-center sm:justify-start`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-3 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-full sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${(props) => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(
  motion.div
)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12 sm:justify-center`;
// const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
// const Card = tw(
//   motion.a
// )`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
// const CardImageContainer = styled.div`
//   ${(props) =>
//     css`
//       background-image: url("${props.imageSrc}");
//     `}
//   ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
// `;
// const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
// const CardRating = styled.div`
//   ${tw`mr-1 text-sm font-bold flex items-end`}
//   svg {
//     ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
//   }
// `;

// const CardHoverOverlay = styled(motion.div)`
//   background-color: rgba(255, 255, 255, 0.5);
//   ${tw`absolute inset-0 flex justify-center items-center`}
// `;
// const CardButton = tw(PrimaryButtonBase)`text-sm`;

// const CardReview = tw.div`font-medium text-xs text-gray-600`;

// const CardText = tw.div`p-4 text-gray-900`;
// const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
// const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
// const CardPrice = tw.p`mt-4 text-xl font-bold`;

const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-start text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  heading = "Checkout the Menu",
  tabs = {
    Managing: [
      {
        imageSrc: publishYourTournamentIcon,
        title: "Publish Your Tournament",
        description:
          "Create and promote your event with a dedicated board for schedules, start times, and updates to keep everyone in the loop.",
      },
      {
        imageSrc: preRegistrationIcon,
        title: "Pre-Registration",
        description:
          "Invite teams effortlessly and manage attendance with customizable registration criteria.",
      },
      {
        imageSrc: onlinePaymentsIcon,
        title: "Online Payments",
        description:
          "Offer early bird specials and discounts with secure online payments.",
      },
      {
        imageSrc: automaticSeedingIcon,
        title: "Automatic Seeding",
        description:
          "Use a point system and head-to-head comparisons for precise and optimal seeding.",
      },
      {
        imageSrc: userFriendlyInterfacesIcon,
        title: "User-Friendly Interface",
        description:
          "Run your tournament effortlessly with intuitive software that minimizes complexity.",
      },
      {
        imageSrc: uptimeGauranteedIcon,
        title: "24/7 uptime guaranteed",
        description:
          "No worries about crashes—our system keeps your tournament up and running around the clock.",
      },
    ],
    Communication: [
      {
        imageSrc: followTeamAndAthletesIcon,
        title: "Follow Teams & Athletes",
        description:
          "Keep up with your favorite teams and athletes—updates automatically appear on your homepage.",
      },
      {
        imageSrc: estimatedMatchTimeIcon,
        title: "Estimated Match Times",
        description:
          "Know where and when you or your wrestler needs to be at your mat.",
      },
      {
        imageSrc: tournamentAnouncementsIcon,
        title: "Tournament Announcements",
        description:
          "Know the time and place your or your wrestler needs to be.",
      },
      {
        imageSrc: liveResultsIcon,
        title: "Live Results",
        description:
          "Get real-time notifications for the wrestlers and teams you follow.",
      },
    ],
    Competing: [
      {
        imageSrc: wrestlerProfilesIcon,
        title: "Wrestler Profiles",
        description: "Store and share your achievements in one place.",
      },
      {
        imageSrc: detailedStatisticsIcon,
        title: "Detailed Statistics",
        description:
          "Break down each match, round by round, to spot strengths and weaknesses.",
      },
      {
        imageSrc: headToHeadComparisonsIcon,
        title: "Head-to-Head Comparisons",
        description: "Review who you've beaten and who's beaten you.",
      },
      {
        imageSrc: weightManagementIcon,
        title: "Weight Management",
        description:
          "Prepare stress-free with clear weight guidelines before every tournament.",
      },
    ],
    Coaching: [
      {
        imageSrc: rosterManagementIcon,
        title: "Roster Management",
        description:
          "Easily manage your wrestlers' info, making tournament registration simple.",
      },
      {
        imageSrc: findAndRegisterForMeetsIcon,
        title: "Find & Register for Meets",
        description:
          "Track and manage all tournaments your team has registered for or attended.",
      },
      // {
      //   imageSrc: accessibleSchedulesIcon,
      //   title: "Accessible Schedules",
      //   description:
      //     "Keep parents and wrestlers informed about upcoming events.",
      // },
      {
        imageSrc: automaticRecordsUpdatesIcon,
        title: "Automatic Record Updates",
        description:
          "Say goodbye to manual updates; profiles are automatically kept current.",
      },
      {
        imageSrc: builtInWeightCriteriaIcon,
        title: "Built-In Weight Criteria",
        description:
          "Ensure wrestlers are only registered in approved weight classes.",
      },
    ],
    Scoring: [
      {
        imageSrc: userFriendlyScoringInterfaceIcon,
        title: "User-Friendly Scoring Interface",
        description:
          "Simplified interface reduces errors and questions during scoring.",
      },
      {
        imageSrc: mobileAndDesktopCompatibilityIcon,
        title: "Mobile & Desktop Compatibility",
        description: "Score matches from any device—phone, tablet, or desktop.",
      },
      {
        imageSrc: realTimeResultsIcon,
        title: "Real-Time Results",
        description:
          "Keep spectators in the loop with up-to-the-minute match results.",
      },
    ],
  },
  imageContainerCss = null,
  imageCss = null,
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{heading}</Header>
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl
                key={index}
                active={activeTab === tabName}
                onClick={() => setActiveTab(tabName)}
              >
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale: 1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale: 0.8,
                display: "none",
              },
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs[tabKey].map((card, index) => (
              <Column key={index}>
                <Card>
                  <span className="imageContainer" css={imageContainerCss}>
                    <img src={card.imageSrc} alt="" css={imageCss} />
                  </span>
                  <span className="title" style={{ textAlign: "left" }}>
                    {card.title}
                  </span>
                  <p className="description" style={{ textAlign: "left" }}>
                    {card.description}
                  </p>
                </Card>
              </Column>
              // <CardContainer key={index}>
              //   <Card
              //     className="group"
              //     href={card.url}
              //     initial="rest"
              //     whileHover="hover"
              //     animate="rest"
              //   >
              //     <CardImageContainer imageSrc={card.imageSrc}>
              //       <CardRatingContainer>
              //         <CardRating>
              //           <StarIcon />
              //           {card.rating}
              //         </CardRating>
              //         <CardReview>({card.reviews})</CardReview>
              //       </CardRatingContainer>
              //       <CardHoverOverlay
              //         variants={{
              //           hover: {
              //             opacity: 1,
              //             height: "auto",
              //           },
              //           rest: {
              //             opacity: 0,
              //             height: 0,
              //           },
              //         }}
              //         transition={{ duration: 0.3 }}
              //       >
              //         <CardButton>Buy Now</CardButton>
              //       </CardHoverOverlay>
              //     </CardImageContainer>
              //     <CardText>
              //       <CardTitle>{card.title}</CardTitle>
              //       <CardContent>{card.content}</CardContent>
              //       <CardPrice>{card.price}</CardPrice>
              //     </CardText>
              //   </Card>
              // </CardContainer>
            ))}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

/* This function is only there for demo purposes. It populates placeholder cards */
const getRandomCards = () => {
  const cards = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Chicken Chilled",
      content: "Chicken Main Course",
      price: "$5.99",
      rating: "5.0",
      reviews: "87",
      url: "#",
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1582254465498-6bc70419b607?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Samsa Beef",
      content: "Fried Mexican Beef",
      price: "$3.99",
      rating: "4.5",
      reviews: "34",
      url: "#",
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1565310022184-f23a884f29da?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Carnet Nachos",
      content: "Chilli Crispy Nachos",
      price: "$3.99",
      rating: "3.9",
      reviews: "26",
      url: "#",
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1534422298391-e4f8c172dddb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Guacamole Mex",
      content: "Mexican Chilli",
      price: "$3.99",
      rating: "4.2",
      reviews: "95",
      url: "#",
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1550461716-dbf266b2a8a7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Chillie Cake",
      content: "Deepfried Chicken",
      price: "$2.99",
      rating: "5.0",
      reviews: "61",
      url: "#",
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1476224203421-9ac39bcb3327??ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Nelli",
      content: "Hamburger & Fries",
      price: "$7.99",
      rating: "4.9",
      reviews: "89",
      url: "#",
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1455619452474-d2be8b1e70cd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Jalapeno Poppers",
      content: "Crispy Soyabeans",
      price: "$8.99",
      rating: "4.6",
      reviews: "12",
      url: "#",
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1473093226795-af9932fe5856?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Cajun Chicken",
      content: "Roasted Chicken & Egg",
      price: "$7.99",
      rating: "4.2",
      reviews: "19",
      url: "#",
    },
  ];

  // Shuffle array
  return cards.sort(() => Math.random() - 0.5);
};
